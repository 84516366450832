<template>
  <div class="msg_details_warp">
    <div class="navBar">
      <div @click="$router.go(-1)" class="back">
        <svg-icon class="leftBtn" iconClass="back"></svg-icon>
      </div>
      <div class="title">{{ data.userName }}</div>
      <router-link
        class="rightBtn"
        :to="{ path: '/userHomePage', query: { uid: $store.getters.userInfo.uid != data.takeUid ? data.takeUid : data.sendUid } }"
      >
        <ImgDecypt :src="data.userAvatar" class="avatar" round />
      </router-link>
    </div>
    <div class="content_info">
      <van-pull-refresh
        v-model="isLoading"
        @refresh="onRefresh"
        success-text="加载成功"
        v-show="!isFirst"
        pulling-text="下拉加载历史消息"
        loosing-text="释放加载"
      >
        <div class="clearBox" v-for="(msg, i) in list" :key="i + '' + msg.id">
          <!-- <div :class="['msg_item', msg.sendUid == userInfo.uid ? 'item_right' : 'item_left']" v-for="(msg, i) in list" :key="i + '' + msg.id">
            <div class="msg_text">
              {{ msg.content }}
            </div>
          </div> -->
          <div class="msg_item leftBox" v-if="msg.sendUid != userInfo.uid">
            <ImgDecypt :src="msg.sendAvatar" class="avatar" round />
            <div class="msg_text">
              {{ msg.content }}
              <div class="leftTriangle"></div>
            </div>
          </div>
          <div class="msg_item rightBox" v-if="msg.sendUid == userInfo.uid">
            <div class="msg_text">
              {{ msg.content }}
              <div class="leftTriangle"></div>
            </div>
            <ImgDecypt :src="userInfo.portrait" class="avatar" round v-if="msg.sendUid == userInfo.uid" />
          </div>
        </div>
      </van-pull-refresh>
      <Loading v-if="isFirst" />
    </div>
    <div class="btn_box" v-show="!isFirst">
      <div class="common_input_box">
        <van-field
          v-model="commentValue"
          placeholder="发一条评论～"
          :class="commentValue ? 'vant80' : 'vant100'"
          autofocus="autofocus"
          class="sendInout"
          @input="changeInput"
        />
        <div class="send" v-if="commentValue.length > 0" @click="sendMessage">发送</div>
      </div>
    </div>
  </div>
</template>
Ò

<script>
import ImgDecypt from '@/components/ImgDecypt';
import { Field, Notify, Toast } from 'vant';
import { getMessageList, sendMsgRead, sendPriLetter } from '@/api/information';
import { mapGetters } from 'vuex';
import { PullRefresh } from 'vant';
import Loading from '@/components/Loading';

export default {
  components: {
    ImgDecypt,
    [Field.name]: Field,
    [PullRefresh.name]: PullRefresh,
    Loading,
  },
  data() {
    return {
      commentValue: '',
      data: {},
      list: [],
      isLoading: false,
      pageNumber: 1,
      pageSize: 20,
      isFirst: true,
      isNodata: false,
      maxlength: 120,
    };
  },
  computed: {
    ...mapGetters({
      userInfo: 'userInfo',
    }),
  },
  created() {
    this.deCodeParams();
    this.getMsgList();
  },
  mounted() {},
  methods: {
    changeInput() {
      if (this.commentValue.length > this.maxlength) {
        this.commentValue = this.commentValue.slice(0, this.maxlength);
      }
      if (this.commentValue.length === this.maxlength) {
        Toast({ message: '最多只能输入' + this.maxlength + '个字符', position: 'bottom' });
      }
    },
    deCodeParams() {
      let data = this.$route.query.data;
      let decodeData = window.atob(data);
      this.data = JSON.parse(window.decodeURI(decodeData));
      // console.log(this.data)
    },
    async getMsgList(type) {
      let req = {
        pageNumber: this.pageNumber,
        pageSize: this.data.noReadNum < 20 ? this.pageSize : this.noReadNum,
        sessionId: this.data.sessionId,
      };

      let res = await this.$Api(getMessageList, req);
      if (res.code === 200) {
        let list = res.data.list || [];
        if (!this.isNodata) {
          if (this.pageNumber === 1) {
            this.list = [];
          }
          this.list.unshift(...list.reverse());
        }
        if (res.data.hasNext) {
          this.pageNumber++;
        }

        if (!res.data.hasNext && this.isLoading) {
          this.isNodata = true;
          Notify({ type: 'success', message: '没有更多消息', background: '#05070b' });
          this.isLoading = false;
          return;
        }
        this.$nextTick(() => {
          if (this.isFirst) {
            this.isFirst = false;
            setTimeout(() => {
              let dom = document.querySelector('.msg_item:last-child');
              if (dom) {
                dom.scrollIntoView();
              }
            }, 0);
          }
        });
        this.isLoading = false;
      }
    },
    async sendMessage() {
      let req = {
        takeUid: this.data.takeUid == this.userInfo.uid ? this.data.sendUid : this.data.takeUid,
        content: this.commentValue.trim(),
      };
      try {
        this.$store.commit('app/SET_LOADING', true);
        let res = await this.$Api(sendPriLetter, req);
        this.$store.commit('app/SET_LOADING', false);
        if (res.code === 200) {
          this.list.push({
            content: req.content,
            takeUid: req.takeUid,
            sendUid: this.userInfo.uid,
            takeAvatar: this.userInfo.portrait,
          });
          this.commentValue = '';
          setTimeout(() => {
            let dom = document.querySelector('.msg_item:last-child');
            if (dom) {
              dom.scrollIntoView();
            }
          }, 0);
          return;
        }
        Toast(res.tip || '发送失败');
      } catch (error) {
        this.$store.commit('app/SET_LOADING', false);
        Toast('发送失败');
      }
    },
    onRefresh() {
      this.isLoading = true;
      this.getMsgList();
    },
    //已读
    read() {
      let newList = this.list.filter((el) => {
        return el.sendUid != this.userInfo.uid;
      });
      let msgIds = newList.map((el) => {
        return el.id;
      });
      let req = {
        msgIds: msgIds,
      };
      this.$Api(sendMsgRead, req);
    },
  },
};
</script>
<style lang="scss" scoped>
.msg_details_warp {
  height: 100%;

  .navBar {
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 16px;
    background-color: #fff;
    box-sizing: border-box;
    border-bottom: 1px solid #e0e0e0;
    .back {
      width: 40px;
      display: flex;
      align-items: center;

      .leftBtn {
        width: 24px;
        height: 24px;
        font-size: 24px;
      }
    }

    .title {
      flex: 1;
      font-size: 18px;
      text-align: center;
      color: #000;
    }

    .rightBtn {
      width: 40px;
      text-align: center;
      .avatar {
        height: 40px;
        width: 40px;
        margin-top: 2px;
      }
    }
  }

  /deep/ .van-pull-refresh {
    height: 100%;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  }

  .content_info {
    height: calc(100% - 145px);
    zoom: 1;
    padding: 15px 0;
    .clearBox {
      padding: 0 12px;
      .msg_item {
        display: flex;
        margin-bottom: 15px;
        .avatar {
          height: 50px;
          width: 50px;
          border-radius: 50%;
          background-color: black;
          margin-right: 10px;
        }
        .msg_text {
          font-size: 12px;
          min-width: 60px;
          max-width: 230px;
          margin-top: 10px;
          background-color: rgb(227, 227, 238);
          display: flex;
          align-items: center;
          padding: 11px 16px;
          box-sizing: border-box;
          position: relative;
          .leftTriangle {
            width: 0;
            height: 0;
            border-top: 8px solid rgb(227, 227, 238);
            border-left: 8px solid transparent;
            border-right: 8px solid transparent;
            position: absolute;
            top: 0;
          }
        }
      }
      .leftBox {
        .msg_text {
          border-radius: 0 20px 20px 8px;
          .leftTriangle {
            left: -6px;
          }
        }
      }
      .rightBox {
        justify-content: flex-end;
        .msg_text {
          border-radius: 20px 0px 8px 20px;
          color: #fff;
          background-color: rgb(255, 103, 143);
          .leftTriangle {
            border-top: 8px solid rgb(255, 103, 143);
            right: -6px;
          }
        }
        .avatar {
          margin-left: 10px;
        }
      }
    }

    // .msg_item {
    //   width: 293px;
    //   word-break: break-all;
    //   color: #ffffff;
    //   position: relative;
    //   margin-bottom: 26px;
    //   .avatar {
    //     height: 50px;
    //     width: 50px;
    //     border-radius: 50%;
    //   }
    //   .msg_text {
    //     font-size: 17px;
    //     border-radius: 8px;
    //     display: inline-block;
    //     background: rgb(227, 227, 238);
    //   }
    // }

    // .item_left::before {
    //   content: '';
    //   position: absolute;
    //   top: 50%;
    //   left: 12px;
    //   width: 10px;
    //   height: 10px;
    //   margin-top: -10px;
    //   background: rgb(227, 227, 238);
    //   /*自动继承父元素的背景*/
    //   transform: rotate(45deg);
    // }

    // .item_left {
    //   float: left;
    //   text-align: left;
    //   padding-left: 16px;
    //   color: rgb(51, 51, 51);
    //   .msg_text {
    //     padding: 12px 12px;
    //   }
    // }

    // .item_right {
    //   float: right;
    //   display: flex;
    //   justify-content: flex-end;
    //   padding-right: 16px;

    //   .msg_text {
    //     padding: 12px 12px;
    //     background: rgb(255, 103, 143);
    //   }
    // }

    // .item_right::before {
    //   content: '';
    //   position: absolute;
    //   top: 50%;
    //   right: 12px;
    //   width: 10px;
    //   height: 10px;
    //   margin-top: -10px;
    //   background: rgb(255, 103, 143);
    //   /*自动继承父元素的背景*/
    //   transform: rotate(45deg);
    // }
  }

  .btn_box {
    width: 100%;
    height: 83px;
    background: #fff;
    position: absolute;
    bottom: 0;

    .common_input_box {
      padding-top: 8px;
      width: 100%;
      height: 50px;
      background: #fff;
      display: flex;
      align-items: center;
      box-sizing: border-box;
      justify-content: space-between;

      /deep/ .van-cell::after {
        border: none;
      }

      /deep/ .van-field__control {
        font-size: 14px;
      }

      /deep/ .van-field {
        background: rgb(243, 243, 243);
        border-radius: 20px;
        margin: 0 16px;
        transition: all 0.5s linear;

        input {
          height: 40px;
          padding: 0 10px;
          background: rgb(243, 243, 243);
          color: #000;
          border: none;
          outline: none !important;
          display: block;
          border-radius: 20px;
        }
      }

      ::placeholder {
        color: rgb(102, 102, 102);
      }

      .send {
        width: 60px;
        height: 40px;
        font-size: 14px;
        color: rgb(102, 102, 102);
        text-align: center;
        line-height: 40px;
        background: rgb(243, 243, 243);
        border-radius: 25px;
        margin-right: 8px;
      }

      .vant100 {
        width: 100%;
        padding: 0;
      }

      .vant80 {
        width: 80%;
        padding: 0;
      }
    }
  }
}
</style>
